<template>
  <Village />
</template>

<script>
import Village from "@/components/village/Village";

export default {
  name: "VillagePage",
  props: {},
  components: {
    Village,
  },
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>
